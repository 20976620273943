<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        @click="
          $router.push($options.ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE)
        "
      >
        Создать категорию статей
      </el-button>
    </div>
    <el-table :data="articleCategories" stripe>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column prop="slug" label="Имя в url" />
      <el-table-column label="Кол-во статей">
        <template slot-scope="scope">
          <span :class="$style.image">
            {{ scope?.row?.blogArticles?.length ?? 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="130">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.UPDATE,
                params: { id: scope?.row?.id },
              })
            "
            :view-link="
              getRoute({
                route: $options.ADDEVENT_ROUTES.CATALOG.CATEGORY,
                params: { slug: scope?.row?.slug },
                site: $options.PROJECTS.ADDEVENTS,
              })
            "
            @delete="deleteArticleCategory(scope?.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPage > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getArticleCategories"
    />
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

import { MODAL_TYPES, SORTING_TYPES } from '@/constants/common'
import {
  ADDEVENT_ADMIN_ROUTES,
  getRoute,
  ADDEVENT_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

export default {
  components: { ActionButtons },
  MODAL_TYPES,
  SORTING_TYPES,
  ADDEVENT_ADMIN_ROUTES,
  ADDEVENT_ROUTES,
  PROJECTS,
  mixins: [notifications],
  data() {
    return {
      articleCategories: [],
      query: {
        page: this.$route.query.page ?? 1,
        limit: 20,
        orderBy: this.$route.query.orderBy ?? 'name',
        orderDir: this.$route.query.orderDir ?? SORTING_TYPES.ASC,
      },
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getArticleCategories()
    this.$router.push({query: this.query})
  },
  async updated() {
    if (JSON.stringify(this.$router.query) !== JSON.stringify(this.query))
      this.$router.push({query: this.query})
  },
  methods: {
    async getArticleCategories() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ArticleCategoriesActions.getList(
          this.query,
        )

      loading.close()

      if (error) return

      this.articleCategories = value?.data
      this.count = value?.meta?.count
    },

    async deleteArticleCategory(category) {
      const isConfirm = confirm(
        `Вы точно хотите удалить категорию: ${category.name}`,
      )

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.ArticleCategoriesActions.delete(
          category.id,
        )

      loading.close()

      if (error)
        return this.showNotification('Ошибка удаления категории', 'error')

      this.showNotification('Категория успешно удалена', 'success')
      await this.getArticleCategories()
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    justify-content: flex-end;
  }

  .pagination {
    @include pagination;
  }
}
</style>
