var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.page},[_c('div',{class:_vm.$style.wrapper},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$options.ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE)}}},[_vm._v(" Создать категорию статей ")])],1),_c('el-table',{attrs:{"data":_vm.articleCategories,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"Название","sortable":""}}),_c('el-table-column',{attrs:{"prop":"slug","label":"Имя в url"}}),_c('el-table-column',{attrs:{"label":"Кол-во статей"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:_vm.$style.image},[_vm._v(" "+_vm._s(scope?.row?.blogArticles?.length ?? 0)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","edit-link":_vm.getRoute({
              route: _vm.$options.ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.UPDATE,
              params: { id: scope?.row?.id },
            }),"view-link":_vm.getRoute({
              route: _vm.$options.ADDEVENT_ROUTES.CATALOG.CATEGORY,
              params: { slug: scope?.row?.slug },
              site: _vm.$options.PROJECTS.ADDEVENTS,
            })},on:{"delete":function($event){return _vm.deleteArticleCategory(scope?.row)}}})]}}])})],1),(_vm.totalPage > 1)?_c('el-pagination',{class:_vm.$style.pagination,attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.query.limit,"total":_vm.count,"current-page":_vm.query.page},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.getArticleCategories}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }